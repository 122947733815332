<template>
    <div class="flex-row align-items-center">
        <CRow class="justify-content-center headimg mb-3">
            <CCol md="12" class="p-0">
                <div style="background-image: url('img/Dashboard.png'); background-position: 50% 50%; background-size: cover; height: 150px;">
                    <h2 class="text-center text-white middle-center"><b>Dashboard</b></h2>
                </div>
            </CCol>
        </CRow>
        <div class="container">
            <CRow class="justify-content-center">
                <CCol md="12" v-if="is_edit">
                    <div class="alert alert-warning mt-3">
                        <CRow class="justify-content-center">
                            <CCol md="7">
                                <b>Perbaruan data perusahaan</b> <br />
                                1. Perbarui data perusahaan menggunakan NIB perusahaan / perorangan yang sudah terdaftar pada aplikasi OSS. <br />
                                2. Jika NIB perusahaan / perorangan berhasil ditemukan, periksa data perusahaan / perorangan pada menu profil. <br />
                                3. Jika NIB perusahaan / perorangan tidak ditemukan / sudah digunakan, hubungi PPVTPP. <br />
                            </CCol>
                            <CCol md="5">
                                <CRow class="mt-4">
                                    <CCol md="8">
                                        <div class="form-group">
                                            <input type="text" class="form-control" v-model="nib_sync" placeholder="Masukkan NIB Perusahaan / Perorangan" />
                                        </div>
                                    </CCol>
                                    <CCol md="4">
                                        <div class="btn btn-primary" v-on:click="syncPerusahaan"><i class="fa fa-refresh"></i> Validasi NIB</div>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                    </div>
                    <div>
                        <object data="assets-download/Panduan_Integrasi_Data_Perusahaan_Atau_Perorangan.pdf" type="application/pdf" width="100%" height="100%">
                            <p>Alternative text - include a link <a href="assets-download/Panduan_Integrasi_Data_Perusahaan_Atau_Perorangan.pdf">to the PDF!</a></p>
                        </object>
                    </div>
                </CCol>
                <CCol md="12" v-if="!is_edit">
                    <CCard class="top-radius-15">
                        <CCardHeader class="top-radius-15 bg-success text-white">
                            <h4 class="my-0 text-center"><b>Semua Permohonan</b></h4>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol md="12" class="mb-3" v-if="is_open">
                                    <router-link :to="{path: 'ajukan-permohonan'}" class="btn btn-outline-success mx-1 btn-block">Ajukan Permohonan</router-link>
                                </CCol>
                            </CRow>
                            <div class="row">
                                <div class="col-md-11">
                                    <div class="row">
                                        <div class="col-md">
                                            <div class="form-group">
                                                <b for="">Tgl Dari</b>
                                                <vuejs-datepicker input-class="form-control no-readonly" v-model="start" format="dd MMM yyyy"></vuejs-datepicker>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div class="form-group">
                                                <b for="">Tgl Sampai</b>
                                                <vuejs-datepicker input-class="form-control no-readonly" v-model="end" format="dd MMM yyyy"></vuejs-datepicker>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <b for="">Tipe Permohonan</b>
                                                <v-select :options="filter_tipe" :reduce="label => label.value" label="label" v-model="is_pemasukan" :value="$store.myValue"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <b for="">Status</b>
                                                <v-select :options="filter_status" :reduce="label => label.value" label="label" v-model="status" :value="$store.myValue"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <br />
                                    <div class="btn btn-sm btn-primary" v-on:click="filterPermohonan"><i class="fa fa-filter"></i> Filter</div>
                                </div>
                            </div>
                            <CRow>
                                <CCol md="12">
                                    <table class="table table-sm table-bordered">
                                        <thead class="bg-dark text-white text-center">
                                            <tr>
                                                <th class="align-top" rowspan="2">No</th>
                                                <th>Kode Permohonan</th>
                                                <th>Module</th>
                                                <th>Permohonan</th>
                                                <th>Status</th>
                        <th>Estimasi Waktu</th>
                                                <th class="align-top" rowspan="2" width="150px;">Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template>
                                                <tr v-if="loadingTable">
                                                    <td colspan="11"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
                                                </tr>
                                                <tr v-for="(value_list_permohonan, key_list_permohonan) in list_permohonan" :key="key_list_permohonan" v-else>
                                                    <td class="align-middle text-center">
                                                        <template>
                                                            <div v-if="key_list_permohonan+1 == 10">
                                                                {{curent_page}}0
                                                            </div>
                                                            <div v-else>
                                                                <template>
                                                                    <span v-if="curent_page-1 != 0">
                                                                        {{curent_page-1}}{{key_list_permohonan+1}}
                                                                    </span>
                                                                    <span v-else>
                                                                        {{key_list_permohonan+1}}
                                                                    </span>
                                                                </template>
                                                            </div>
                                                        </template>
                                                    </td>
                                                    <td class="align-middle text-center">{{value_list_permohonan.kode}}</td>
                                                    <td>
                                                        <table class="table table-sm table-borderless">
                                                            <tr>
                                                                <td style="width: 150px;">Kategori Module</td>
                                                                <td style="width: 15px;">:</td>
                                                                <th>{{value_list_permohonan.pr_kategori}}</th>
                                                            </tr>
                                                            <tr>
                                                                <td>Module</td>
                                                                <td>:</td>
                                                                <th>{{value_list_permohonan.m_nama}}</th>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td>
                                                        <table class="table table-sm table-borderless">
                                                            <!-- <tr>
                                                                <td style="width: 150px">Nomor Surat</td>
                                                                <td style="width: 15px">:</td>
                                                                <th>{{value_list_permohonan.pr_nomor_surat}}</th>
                                                            </tr> -->
                                                            <tr>
                                                                <td>Tanggal Pengajuan</td>
                                                                <td>:</td>
                                                                <th>
                                                                    <template>
                                                                        <span v-if="value_list_permohonan.pr_tgl_pengajuan">{{value_list_permohonan.pr_tgl_pengajuan}}</span>
                                                                        <span v-else>Data Tidak Tersedia</span>
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td>Tanggal Verifikasi</td>
                                                                <td>:</td>
                                                                <th>
                                                                    <template>
                                                                        <span v-if="value_list_permohonan.pr_tgl_verifikasi">{{value_list_permohonan.pr_tgl_verifikasi}}</span>
                                                                        <span v-else>Data Tidak Tersedia</span>
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td class="align-middle text-center">
                                                        <template>
                                                            <CBadge
                                                                :color="getBadge(value_list_permohonan.status_permohonan)"
                                                                v-if="value_list_permohonan.status_permohonan == 'Pengajuan Permohonan'  || value_list_permohonan.status_permohonan ==  'Draft Permohonan'"
                                                            >
                                                                <div>
                                                                    Draft
                                                                </div>
                                                            </CBadge>
                                                            <CBadge :color="getBadge(value_list_permohonan.status_permohonan)" v-else>
                                                                <div>
                                                                    {{value_list_permohonan.status_permohonan}}
                                                                </div>
                                                            </CBadge>
                                                        </template>
                                                    </td>
                          <td>
                            <!-- <table
                              class="table table-sm table-borderless"
                              v-if="value_list_permohonan.estimasi_hari"
                            >
                              <tr>
                                <td>Hari</td>
                                <td>:</td>
                                <th>
                                  {{ value_list_permohonan.estimasi_hari }} Hari
                                  kerja
                                </th>
                              </tr>
                              <tr>
                                <td>Tgl Selesai</td>
                                <td>:</td>
                                <th>{{ value_list_permohonan.due_date }}</th>
                              </tr>
                            </table> -->
                            <template
                              v-if="value_list_permohonan.estimasi_hari"
                            >
                              {{ value_list_permohonan.kategori }}
                            </template>
                          </td>
                                                    <td class="align-middle text-center">
                                                        <template>
                                                            <div v-if="value_list_permohonan.is_pemasukan">
                                                                <template>
                                                                    <div v-if="value_list_permohonan.status_permohonan ==  'Menunggu Perbaikan'">
                                                                        <router-link
                                                                            :to="{path: 'edit-perbaikan-permohonan-pemasukan/'+value_list_permohonan.pr_kategori.replaceAll(' ','-')+'/'+value_list_permohonan.id}"
                                                                            class="btn btn-block btn-warning btn-sm"
                                                                        >
                                                                            <i class="fa fa-pencil"></i> Edit
                                                                        </router-link>
                                                                    </div>
                                                                    <div v-else-if="value_list_permohonan.status_permohonan ==  'Pengajuan Permohonan' || value_list_permohonan.status_permohonan ==  'Draft Permohonan'">
                                                                        <router-link
                                                                            :to="{path: 'edit-permohonan-pemasukan-draft/'+value_list_permohonan.pr_kategori.replaceAll(' ','-')+'/'+value_list_permohonan.id}"
                                                                            class="btn btn-block btn-warning btn-sm"
                                                                        >
                                                                            <i class="fa fa-pencil"></i> Edit
                                                                        </router-link>
                                                                        <div v-on:click="deletePermohonan(value_list_permohonan.id)" class="btn btn-block btn-danger btn-sm"><i class="fa fa-times"></i> Hapus</div>
                                                                    </div>
                                                                    <div v-else>
                                                                        <router-link
                                                                            :to="{path: 'detail-permohonan-pemasukan/'+value_list_permohonan.pr_kategori.replaceAll(' ','-')+'/'+value_list_permohonan.id}"
                                                                            class="btn btn-block btn-info btn-sm"
                                                                        >
                                                                            <i class="fa fa-eye"></i> Detail
                                                                        </router-link>
                                                                    </div>
                                                                </template>
                                                            </div>
                                                            <div v-else>
                                                                <template>
                                                                    <div v-if="value_list_permohonan.status_permohonan ==  'Menunggu Perbaikan'">
                                                                        <router-link
                                                                            :to="{path: 'edit-perbaikan-permohonan-pengeluaran/'+value_list_permohonan.pr_kategori.replaceAll(' ','-')+'/'+value_list_permohonan.id}"
                                                                            class="btn btn-block btn-warning btn-sm"
                                                                        >
                                                                            <i class="fa fa-pencil"></i> Edit
                                                                        </router-link>
                                                                    </div>
                                                                    <div v-else-if="value_list_permohonan.status_permohonan ==  'Pengajuan Permohonan' || value_list_permohonan.status_permohonan ==  'Draft Permohonan'">
                                                                        <router-link
                                                                            :to="{path: 'edit-permohonan-pengeluaran-draft/'+value_list_permohonan.pr_kategori.replaceAll(' ','-')+'/'+value_list_permohonan.id}"
                                                                            class="btn btn-block btn-warning btn-sm"
                                                                        >
                                                                            <i class="fa fa-pencil"></i> Edit
                                                                        </router-link>
                                                                        <div v-on:click="deletePermohonan(value_list_permohonan.id)" class="btn btn-block btn-danger btn-sm"><i class="fa fa-times"></i> Hapus</div>
                                                                    </div>
                                                                    <div v-else>
                                                                        <router-link :to="{path: 'detail-permohonan-pengeluaran/'+value_list_permohonan.pr_kategori.replaceAll(' ','-')+'/'+value_list_permohonan.id}" class="btn btn-block btn-info btn-sm">
                                                                            <i class="fa fa-eye"></i> Detail
                                                                        </router-link>
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </template>

                                                        <template>
                                                            <div v-if="value_list_permohonan.status_permohonan ==  'Selesai' && value_list_permohonan.pr_status_realisasi != 'lengkap'">
                                                                <router-link :to="{name: 'LaporanPemohonRealisasi', params: { idpermohonan: value_list_permohonan.id }}" class="btn btn-block btn-success btn-sm mt-1">
                                                                    <i class="fa fa-hand-o-right"></i> Lapor Realisasi
                                                                </router-link>
                                                            </div>
                                                            <div v-else-if="value_list_permohonan.status_permohonan ==  'Selesai' && value_list_permohonan.pr_status_realisasi == 'lengkap'">
                                                                <div class="alert alert-success mt-1">Realisasi Sudah Sesuai</div>
                                                            </div>
                                                            <div v-else></div>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                    <nav aria-label="pagination">
                                        <ul class="pagination justify-content-start">
                                            <!---->
                                            <!-- {{ page.active ? 'active' : '' }} -->
                                            <li v-for="(page, key_page) in paging" v-bind:class="{ 'active': page.active }" :key="key_page" class="page-item">
                                                <div v-on:click="toPage(page.url)" class="page-link c-page-link-number">
                                                    <div v-html="page.label"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    </div>
</template>
<script>
    import "core-js/stable";
    import Vue from "vue";
    const null_array = [];
    export default {
        name: "DashboardPemohon",
        components: {
            vuejsDatepicker,
        },
        data() {
            return {
                list_permohonan: null_array,
                paging: null_array,
                curent_page: "",
                session_data: JSON.parse(this.session),
                reload_already: this.reload_already,
                curent_page: "",
                loadingTable: true,
                is_edit: false,
                nib_sync: "",
                is_open: false,
                is_pemasukan: "",
                start: "",
                end: "",
                status: "",
                filter_status: [
                    {
                        value: "draft",
                        label: "Draft",
                    },
                    {
                        value: "baru",
                        label: "Baru",
                    },
                    {
                        value: "menunggu perbaikan",
                        label: "Menunggu Perbaikan",
                    },
                    {
                        value: "dibatalkan",
                        label: "Dibatalkan",
                    },
                    {
                        value: "proses",
                        label: "Proses",
                    },
                    {
                        value: "selesai",
                        label: "Selesai",
                    },
                ],
                filter_tipe: [
                    {
                        value: "1",
                        label: "Pemasukkan Benih Tanaman",
                    },
                    {
                        value: "0",
                        label: "Pengeluaran Benih Tanaman",
                    },
                ],
            };
        },
        mounted() {
            $(".no-readonly").prop("readonly", false);
        },
        methods: {
            deletePermohonan(idPermohonan) {
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: "btn btn-danger mr-2",
                        denyButton: "btn btn-secondary ml-2",
                    },
                    buttonsStyling: false,
                });
                swalWithBootstrapButtons
                    .fire({
                        title: "Apakah anda yakin ?",
                        text: "Permohonan yang sudah dihapus, tidak dapat dikembalikan !",
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: `Hapus`,
                        denyButtonText: `Batal`,
                    })
                    .then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            Swal.fire({
                                title: '<i class="fa fa-refresh fa-spin"></i>',
                                text: "Loading...",
                                showConfirmButton: false,
                            });
                            var FormData = require("form-data");
                            var data = new FormData();
                            data.append("id", idPermohonan);
                            var config = {
                                method: "post",
                                url: this.apiLink + "api/permohonan/delete_permohonan",
                                headers: {
                                    Authorization: "Bearer " + this.access_token,
                                },
                                data: data,
                            };
                            axios(config).then((response) => {
                                var res_data = response.data;
                                // console.log(res_data);
                                if (res_data.meta.code != "200") {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: res_data.data.message,
                                    });
                                } else {
                                    let timerInterval;
                                    Swal.fire({
                                        icon: "success",
                                        title: "Berhasil",
                                        text: "Data permohonan berhasil dihapus.",
                                        timer: 2000,
                                        timerProgressBar: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        didOpen: () => {
                                            timerInterval = setInterval(() => {
                                                const content = Swal.getContent();
                                                if (content) {
                                                    const b = content.querySelector("b");
                                                    if (b) {
                                                        b.textContent = Swal.getTimerLeft();
                                                    }
                                                }
                                            }, 100);
                                        },
                                        willClose: () => {
                                            clearInterval(timerInterval);
                                        },
                                    }).then((result) => {
                                        /* Read more about handling dismissals below */
                                        if (result.dismiss === Swal.DismissReason.timer) {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                        } else if (result.isDenied) {
                            Swal.close();
                        }
                    });
            },
            syncPerusahaan() {
                let self = this;
                // this.newSession();
                // return false;
                Swal.fire({
                    title: '<i class="fa fa-refresh fa-spin"></i>',
                    text: "Mengambil data dari OSS, Mohon tunggu sebentar...",
                    showConfirmButton: false,
                });
                axios
                    .put(
                        this.apiLink + "api/crud/user/perusahaan",
                        {
                            perusahaan_id: this.session_data.perusahaan_id,
                            nib: this.nib_sync,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + this.access_token,
                            },
                        }
                    )
                    .then(function (response) {
                        var res_data = response.data;
                        if (res_data.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: res_user.data.message,
                            });
                        } else {
                            self.newSession();
                        }
                    })
                    .catch((error) => {
                        // console.log(error.response.data.data.message);
                        if (error) {
                            if (error.response.data.data.message == "Service inqueryNIB hanya untuk jenis NIB Perseorangan dan Non Perseorangan") {
                                Swal.fire({
                                    icon: "error",
                                    title: "Gagal",
                                    text: "NIB IMUK tidak dapat diajukan dalam permohonan Pemasukan dan Pengeluaran Benih Hortikultura Hanya NIB Perorangan (untuk koleksi) dan NIB Perusahaan Berbadan Usaha",
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Gagal",
                                    text: error.response.data.data.message,
                                });
                            }
                        }
                    });
                // alert(this.nib_sync);
            },
            filterPermohonan() {
                this.loadingTable = true;
                let string_search = "";
                axios
                    .get(this.apiLink + "api/permohonan", {
                        params: {
                            is_pemasukan: this.is_pemasukan,
                            start: this.start,
                            end: this.end,
                            status: this.status,
                            // roles_id: this.session_data.roles_id,
                            search: string_search,
                            nib: this.session_data.nib,
                        },
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    })
                    .then((response) => {
                        var res_permohonan = response.data;
                        var res_permohonan_data = res_permohonan.data[0];
                        // console.log(res_permohonan_data);
                        if (res_permohonan.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: res_permohonan.data.message,
                            });
                        } else {
                            this.loadingTable = false;
                            this.list_permohonan = res_permohonan_data.data;
                            this.paging = res_permohonan_data.links;
                            this.curent_page = res_permohonan_data.current_page;
                        }
                    });
            },
            newSession() {
                axios
                    .get(this.apiLink + "api/user", {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    })
                    .then((response) => {
                        // console.log(response);
                        // Tab data Akun
                        // var datauser = response.data.data;
                        Vue.prototype.session = JSON.stringify(response.data.data);
                        localStorage.session = JSON.stringify(response.data.data);

                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Data perusahaan anda berhasil di update",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getContent();
                                    if (content) {
                                        const b = content.querySelector("b");
                                        if (b) {
                                            b.textContent = Swal.getTimerLeft();
                                        }
                                    }
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                window.location.reload();
                            }
                        });
                    });

                // alert('wd');
            },
            getBadge(status) {
                switch (status) {
                    case "draft":
                        return "bg-dark";
                    case "Menunggu Perbaikan":
                        return "warning";
                    case "Pengajuan Permohonan":
                        return "warning";
                    case "Draft Permohonan":
                        return "warning";
                    case "Ditolak":
                        return "danger";
                    case "Selesai":
                        return "success";
                    case "ajukan":
                        return "info";
                    case "Dibatalkan":
                        return "danger";
                    default:
                        return "info";
                }
            },
            toPage(url) {
                this.loadingTable = true;
                var numpage = url.replace(this.apiLink + "api/permohonan?page=", "");
                axios
                    .get(url, {
                        params: {
                            status: this.status,
                        },
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    })
                    .then((response) => {
                        var res_permohonan = response.data;
                        var res_permohonan_data = res_permohonan.data[0];
                        // console.log(res_permohonan_data);
                        if (res_permohonan.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: res_permohonan.data.message,
                            });
                        } else {
                            this.loadingTable = false;
                            this.list_permohonan = res_permohonan_data.data;
                            this.paging = res_permohonan_data.links;
                            this.curent_page = res_permohonan_data.current_page;
                            this.curent_page = res_permohonan_data.current_page;
                        }
                    });
            },
        },
        beforeCreate() {
            if (this.reload_already == "no") {
                Vue.prototype.reload_already = "yes";
                localStorage.reload_already = "yes";
                window.location.reload();
            }
        },
        created() {
            let self = this;

            // console.log(this.session_data);
            console.log(this.access_token);
            if (this.session_data.nib == null) {
                this.is_edit = true;
            }
            axios
                .get(this.apiLink + "api/permohonan", {
                    params: {
                        nib: this.session_data.nib,
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_permohonan = response.data;
                    var res_permohonan_data = res_permohonan.data[0];
                    if (res_permohonan.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_permohonan.data.message,
                        });
                    } else {
                        this.loadingTable = false;
                        this.list_permohonan = res_permohonan_data.data;
                        this.paging = res_permohonan_data.links;
                        this.curent_page = res_permohonan_data.current_page;
                        this.curent_page = res_permohonan_data.current_page;
                    }
                    // console.log(res_permohonan_data);
                });
            axios
                .get(this.apiLink + "api/crud/konfigurasi", {
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_konfigurasi = response.data;
                    var res_konfigurasi_data = res_konfigurasi.data[0];
                    // console.log(res_konfigurasi_data);
                    if (res_konfigurasi.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_konfigurasi.data.message,
                        });
                    } else {
                        var open_apli = res_konfigurasi_data.find((options) => {
                            if (options.id === 1) {
                                return options;
                            }
                        });
                        if (open_apli.k_aksi != "open") {
                            self.is_open = true;
                        }
                    }
                });
        },
    };
</script>
